<template>
  <div
    v-if="loyaltyBreakdown.points_earned_with_cart"
    class="border-t border-b border-ink-1 mt-4">
    <p
      class="text-center py-4"
      :class="{ 'md:py-6 px-4': location === 'basketPage' }">
      {{
        t("cart.paper-club.points-earned", {
          pointsEarned: loyaltyBreakdown.points_earned_with_cart
        })
      }}
      {{
        t("cart.paper-club.points-to-next-reward", {
          pointsToNextReward: loyaltyBreakdown.points_to_next_reward
        })
      }}
      <strong>
        {{
          t("cart.paper-club.credit-amount", {
            creditAmount: loyaltyBreakdown.next_reward_amount.formatted
          })
        }}
      </strong>
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "RewardInfo",
  props: {
    location: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapState({
      loyaltyBreakdown: state => state.cart.loyaltyBreakdown
    })
  }
};
</script>
