<template>
  <p
    v-if="!deal.complete"
    class="mb-3 typeset-8"
    v-html="subtotalDescription"></p>
  <p
    v-else
    class="mb-3 typeset-8"
    v-html="completeMessage"></p>
  <div class="bg-paper w-full">
    <div
      class="bg-spearmint h-2 progress-line mb-4"
      :style="{ transform: `scaleX(${deal.progress / 100})` }"></div>
  </div>
</template>

<script>
export default {
  name: "SubtotalDeal",
  props: {
    deal: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showConfetti: false
    };
  },
  computed: {
    subtotalDescription() {
      // Hardcode 'Spend' because it won't need to change and goes before the number amount, promptCopy is the text that goes after
      // Eg: "Spend £10 more to get 20% off your order!"
      return `Spend ${this.valueRemaining} ${this.promptCopy} ${this.link}`;
    },
    link() {
      if (this.ctaText && this.ctaLink) {
        return `<a class='underline whitespace-no-wrap' href=${this.ctaLink}>${this.ctaText}</a>`;
      }
      return "";
    },
    valueRemaining() {
      return this.deal.valueRemaining.formatted;
    },
    promptCopy() {
      return this.deal.promptMessage;
    },
    completeMessage() {
      return this.deal.completeMessage;
    },
    ctaLink() {
      return this.deal.ctaLink;
    },
    ctaText() {
      return this.deal.ctaText;
    }
  },
  mounted() {
    analytics.track("Viewed Deal Indicator", {
      deal: this.deal.name
    });
  },
  methods: {
    triggerAnalyticsEvent() {
      analytics.track("Clicked Deal Indicator", {
        deal: this.deal.name
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.progress-line {
  transition: transform 1s ease-in;
  transform-origin: left center;
}
</style>
